import React from 'react';

function Armodel() {
    return (
      <div>
        <a href="intent://arvr.google.com/scene-viewer/1.0?file=https:/test.digital-skylines.fr/test.glb&mode=ar_only&link=https://test.digital-skylines.fr#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;end;">
            Appuyez sur l'image pour voir le modèle en AR sur Android
        </a>
      </div>
    );
  }
  

  export default Armodel